html {
  box-sizing: border-box;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: inherit;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Verdana, sans-serif,
    "tradingembassy";
  font-weight: 400;
  padding: 0;
  margin: 0;
}

h1 {
  line-height: 2.5rem;
  font-size: 24px;
  letter-spacing: 0.05rem;
  color: rgba(45, 42, 38, 1);
  text-transform: none;
}

h3 {
  font-size: 1.125rem;
}

h5 {
  font-size: 14px;
}

a {
  color: rgba(255, 255, 255, 0.8);
}

.color-black {
  color: rgba(45, 42, 38, 1);
}

.color-white {
  color: rgba(255, 255, 255, 1);
}

input[type="text"] {
  box-sizing: border-box;
}

input[type="password"] {
  box-sizing: border-box;
}
